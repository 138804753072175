import React from 'react'

import { curriculum } from '../data/landing'

import RegisterButton from './RegisterButton'
import StatusTag from './StatusTag'

const CurriculumSection = () => {
  return (
    <div className="flex flex-col items-center justify-center py-20">
      <h2 className="text-5xl md:text-6xl font-semibold mb-12 text-center">
        Our <span className="text-primary">Curriculum</span>
      </h2>

      <div className="mb-10 flex items-stretch justify-center flex-wrap w-full max-w-screen-lg">
        {curriculum
          .filter((lesson) => lesson.show)
          .map((lesson) => (
            <div key={lesson.title} className="w-full md:w-1/2 py-4 px-5">
              <div className="min-h-full bg-zinc-800 rounded-md shadow-sm p-6">
                <div className="flex items-center justify-between">
                  <lesson.icon className="w-12 h-12" />
                  <StatusTag tag={lesson.tag} />
                </div>
                <h3 className="text-white font-medium text-2xl my-4">
                  {lesson.title}
                </h3>

                <p className="font-normal text-zinc-400">{lesson.desc}</p>
              </div>
            </div>
          ))}
      </div>

      <RegisterButton />
    </div>
  )
}

export default CurriculumSection
