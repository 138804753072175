import { LinkedinFilledIcon, TwitterIcon } from 'assets/icons'
import React from 'react'

import { founders } from './data/founder'

const FounderSection = () => {
  return (
    <div className="flex flex-col relative">
      <h2 className="text-3xl md:text-5xl font-semibold text-center mb-5">
        Meet Our <span className="text-primary">Founders</span>
      </h2>

      <div className="flex flex-wrap justify-center items-stretch mx-auto">
        {founders.map((founder) => (
          <div className="m-5 pt-24" key={founder.name}>
            <div className="flex items-center space-x-8 px-5 py-10 bg-indigo-700 rounded-lg">
              {/* Left */}
              <div>
                <img
                  loading="lazy"
                  src={founder.image}
                  alt="founder"
                  className="h-44 w-44 rounded-full -mt-32"
                />
                <div className="pt-3">
                  <div className="flex flex-col space-y-1 text-center">
                    <div className="text-2xl whitespace-nowrap">
                      {founder.name}
                    </div>
                    <div className="text-base opacity-70 whitespace-nowrap">
                      {founder.title}
                    </div>
                    <div className="text-base whitespace-nowrap">
                      {founder.subtitle}
                    </div>
                    <div className="flex justify-center space-x-5 pt-3">
                      <a
                        href={founder.twitter}
                        target="_blank"
                        rel="noopener noreferrer">
                        <TwitterIcon className="h-7 w-7 opacity-70 cursor-pointer" />
                      </a>
                      <a
                        href={founder.linkedin}
                        target="_blank"
                        rel="noopener noreferrer">
                        <LinkedinFilledIcon className="h-7 w-7 opacity-70 cursor-pointer" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* Right */}
              <div className="w-80 font-light hidden md:block">
                {founder.plot}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default FounderSection
