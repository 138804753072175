import { Link, RadialGradient } from 'components'
import React from 'react'

import { students_messages } from '../data/landing'

const StudentsFeedBackSection = () => {
  return (
    <div className="flex flex-col items-center justify-center py-20 relative">
      <RadialGradient top="-40%" left="-300px" />

      <h2 className="text-5xl md:text-6xl text-center font-semibold mb-12 px-6">
        Message From Our <span className="text-primary">Students</span>
      </h2>

      <div className="w-full flex flex-col md:flex-row p-6 max-w-screen-2xl space-y-6 md:space-y-0 md:space-x-6">
        {students_messages.map((student, idx) => (
          <div
            key={idx}
            className="bg-zinc-800 rounded px-7 py-8 gap-8 flex flex-col sm:flex-row w-full md:w-5/6 mx-auto">
            <img
              loading="lazy"
              src={student.image}
              alt="student"
              className="h-40 w-40"
            />

            <div className="flex flex-col">
              <span className="text-2xl font-medium mb-3">{student.name}</span>
              <span className="text-xl font-medium text-zinc-500 mb-2">
                {student.company}
              </span>
              <p>{student.message}</p>
            </div>
          </div>
        ))}
      </div>

      <Link
        href="/pages/community"
        className="text-primary hover:text-primary-light transition-colors font-medium text-2xl mt-12 text-center mx-2">
        Checkout Our Wall of Love →
      </Link>
    </div>
  )
}

export default StudentsFeedBackSection
