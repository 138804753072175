import React, { useState } from 'react'

import { tx } from '@twind/core'

import { PlayIcon } from '../assets/images/icons'

const EmpoweredStudentCard = (props: {
  name: string
  link: string
  img: string
  companyImg: string
  onClick: (_link: string) => void
}) => {
  const { name, link, img, companyImg, onClick } = props
  const [enter, setEnter] = useState(false)

  return (
    <button className="block group relative bg-white rounded-lg cursor-default overflow-hidden space-y-4 m-5 justify-around pb-4 w-60 z-10 focus:outline-none">
      <img
        loading="lazy"
        src={img}
        alt="student"
        className="h-60 w-60 w-full object-center object-cover"
      />

      <h2 className="text-lg text-zinc-800 font-semibold text-center">
        {name}
      </h2>

      <p className="text-zinc-600 text-base text-center">Placed at</p>

      <img
        loading="lazy"
        src={companyImg}
        alt="company logo"
        className="h-8 w-full object-contain object-center"
      />

      <span
        className={tx(
          'absolute inset-0 bg-zinc-800 opacity-0 !mt-0 transition-all duration-500',
          { '!opacity-30': enter }
        )}
      />

      <span
        className="absolute cursor-pointer flex items-center justify-center top-[40%] left-1/2 -translate-x-1/2 p-3 rounded bg-white hidden group-hover:inline-block shadow-md transition-all hover:scale-150"
        onMouseEnter={() => setEnter(true)}
        onMouseLeave={() => setEnter(false)}
        onClick={() => onClick(link)}>
        <PlayIcon height="18px" width="17px" />
      </span>
    </button>
  )
}

export default EmpoweredStudentCard
