import { Link } from 'components'
import React from 'react'
import Lottie from 'react-lottie'

import { ChevronUpIcon } from '@heroicons/react/solid'

import { heroSectionAnimation } from '../../assets/animations/landing_page'
import gradientBanner from '../../assets/images/gradient-banner.svg'

const DevsnestBootCampHeroSection = ({ to }: { to: string }) => {
  return (
    <>
      <img
        src={gradientBanner.src}
        alt="gradient-banner"
        className="absolute w-full"
        style={{ top: 0, left: 0 }}
      />
      <div className="min-h-[90vh] flex flex-col-reverse md:flex-row items-center justify-evenly px-8 max-w-7xl mx-auto">
        <div className="flex flex-col z-10 md:w-[65%]">
          <div className="text-2xl md:text-3xl lg:text-5xl font-semibold">
            <h1 className="relative">
              The 7 month
              <span className="relative hidden sm:inline-block">
                <ChevronUpIcon className="inline font-bold h-11 w-11 mb-2" />
                <span className="transform left-1/2 -translate-x-1/2 bottom-[85%] text-lg px-3 py-2 absolute rounded-md bg-green-600">
                  FREE
                </span>
              </span>
              <span className="sm:hidden inline-block mx-2 text-primary">
                FREE
              </span>
              Fullstack Bootcamp To Get
              <span className="border-primary border-b-4 mr-2"> YOU</span>
              The Best Tech Job.
            </h1>
          </div>

          {/* <p className="mt-8 md:mt-14 text-xl"> */}
          {/* At ZERO COST [No hidden charges, NO ISA] <br /> */}
          {/* Launching on 20th June. Register Now [Limited seats] */}
          {/* </p> */}

          <div className="flex flex-col gap-6 mt-7 md:mt-16">
            <div>
              <Link
                href={to}
                className="inline-block bg-primary hover:bg-primary-dark transition-colors mr-4 md:mr-8 px-4 md:px-7 py-2 md:py-4 rounded-md text-base md:text-xl">
                Start Learning
              </Link>

              {/* <Link
              href="/discord"
              className="block bg-gray-700 hover:bg-gray-800 transition-colors md:mr-8 px-4 md:px-6 py-2 md:py-3 rounded-md text-base md:text-lg">
              Join Discord
            </Link> */}
            </div>

            <p className="text-lg">
              For more information about the course checkout{' '}
              <span className="font-semibold text-primary hover:text-primary-dark px-1.5 py-1  hover:border-1 hover:border-primary rounded-sm cursor-pointer">
                <a
                  className="hover:scale-125"
                  href="https://water-pin-778.notion.site/THE-Devsnest-Bootcamp-A-to-Z-82dd13410b374754b2ff934c70561416"
                  target="_blank"
                  rel="noreferrer">
                  here &#8594;
                </a>
              </span>
            </p>
          </div>
        </div>

        <div className="hidden md:block w-full md:w-[35%]">
          <Lottie {...heroSectionAnimation} />
        </div>
      </div>
    </>
  )
}

export default DevsnestBootCampHeroSection
