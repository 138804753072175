import * as React from 'react'

export const ExpandIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.85274 0.792641C6.60421 0.79264 6.40274 0.994112 6.40274 1.24264C6.40274 1.49117 6.60421 1.69264 6.85274 1.69264L13.6152 1.69264L13.6152 8.45513C13.6152 8.70366 13.8167 8.90513 14.0652 8.90513C14.3138 8.90513 14.5152 8.70366 14.5152 8.45513L14.5152 1.24264C14.5152 0.994112 14.3138 0.79264 14.0652 0.792641H6.85274Z"
        fill="#8080FF"
      />
      <path
        d="M8.45551 14.5148C8.70404 14.5148 8.90551 14.3134 8.90551 14.0648C8.90551 13.8163 8.70404 13.6148 8.45551 13.6148L1.69303 13.6148L1.69302 6.85236C1.69302 6.60383 1.49155 6.40236 1.24302 6.40236C0.994496 6.40236 0.793025 6.60383 0.793025 6.85236L0.793026 14.0648C0.793027 14.3134 0.994498 14.5148 1.24303 14.5148H8.45551Z"
        fill="#8080FF"
      />
      <path
        d="M6.85274 0.792641C6.60421 0.79264 6.40274 0.994112 6.40274 1.24264C6.40274 1.49117 6.60421 1.69264 6.85274 1.69264L13.6152 1.69264L13.6152 8.45513C13.6152 8.70366 13.8167 8.90513 14.0652 8.90513C14.3138 8.90513 14.5152 8.70366 14.5152 8.45513L14.5152 1.24264C14.5152 0.994112 14.3138 0.79264 14.0652 0.792641H6.85274Z"
        stroke="#8080FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.45551 14.5148C8.70404 14.5148 8.90551 14.3134 8.90551 14.0648C8.90551 13.8163 8.70404 13.6148 8.45551 13.6148L1.69303 13.6148L1.69302 6.85236C1.69302 6.60383 1.49155 6.40236 1.24302 6.40236C0.994496 6.40236 0.793025 6.60383 0.793025 6.85236L0.793026 14.0648C0.793027 14.3134 0.994498 14.5148 1.24303 14.5148H8.45551Z"
        stroke="#8080FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const SqueezIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.4557 9.51495C19.7042 9.51495 19.9057 9.31348 19.9057 9.06495C19.9057 8.81642 19.7042 8.61495 19.4557 8.61495L12.6932 8.61495L12.6932 1.85246C12.6932 1.60393 12.4917 1.40246 12.2432 1.40246C11.9946 1.40246 11.7932 1.60393 11.7932 1.85246L11.7932 9.06495C11.7932 9.31347 11.9946 9.51495 12.2432 9.51495H19.4557Z"
      fill="#A3A3FF"
      stroke="#A3A3FF"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.85274 11.7926C1.60421 11.7926 1.40274 11.9941 1.40274 12.2426C1.40274 12.4912 1.60421 12.6926 1.85274 12.6926L8.61523 12.6926L8.61523 19.4551C8.61523 19.7037 8.8167 19.9051 9.06523 19.9051C9.31376 19.9051 9.51523 19.7037 9.51523 19.4551L9.51523 12.2426C9.51523 11.9941 9.31376 11.7926 9.06523 11.7926H1.85274Z"
      fill="#A3A3FF"
      stroke="#A3A3FF"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const CurveArrowDownIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 42 43" fill="currentColor" {...props}>
      <path d="M23.3425 34.9802C23.6601 34.4838 23.9819 33.9928 24.2906 33.4945C25.5267 31.4833 26.354 29.2659 26.838 26.8785C27.6501 22.9069 27.4792 18.8445 26.6665 14.7258C25.9391 11.057 24.7147 7.51407 23.1063 4.06333C22.5865 2.9551 22.0094 1.87287 21.4274 0.794338C21.2681 0.49958 21.2134 0.561309 21.0459 0.696059C20.6717 0.995109 20.2844 1.28692 19.8937 1.5641C19.7376 1.67314 19.72 1.77214 19.8232 1.96009C20.7518 3.6517 21.5814 5.37508 22.3104 7.14854C23.3497 9.69096 24.1745 12.275 24.6996 14.9306C25.3977 18.4586 25.6123 21.9567 25.0299 25.3866C24.5485 28.2263 23.5662 30.8091 21.9861 33.0881C21.6088 33.638 21.1929 34.1571 20.6867 34.6584C20.6525 34.3071 20.6274 33.9574 20.5932 33.6061C20.4317 31.9022 20.1914 30.2062 19.7578 28.5188C19.6305 28.0233 19.2539 27.7071 18.8203 27.6824C18.2629 27.6567 17.882 27.9265 17.7889 28.4251C17.7521 28.6415 17.8021 28.8609 17.8521 29.0803C18.4527 31.5505 18.6903 34.0284 18.6713 36.5025C18.6706 36.7259 18.5637 36.8493 18.4158 36.9693C17.8463 37.4069 17.8377 37.722 18.1994 38.4265C18.3687 38.7532 18.5168 39.113 18.5347 39.4938C18.5526 39.8746 18.7833 40.1808 19.1387 40.3158C19.8544 40.5823 20.4374 40.2544 20.5901 39.488C20.6319 39.268 20.7215 39.1319 20.8923 39.0118C22.8989 37.6457 25.0756 36.5971 27.3984 35.8241C27.6914 35.7234 27.996 35.6482 28.2882 35.5566C28.4437 35.508 28.529 35.5294 28.5897 35.7323C28.6968 36.0887 28.7107 36.0868 28.9902 35.8762C29.7158 35.3295 30.438 34.7682 31.176 34.238C31.3811 34.092 31.4084 33.9343 31.3148 33.6877C31.1045 33.1433 30.5632 32.8227 30.0366 32.9194C28.1832 33.2444 26.407 33.7942 24.6837 34.4754C24.2468 34.6503 23.8173 34.8453 23.3837 35.0349C23.3582 35.0113 23.3499 35.0003 23.3425 34.9802Z" />
    </svg>
  )
}

export const PlayIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg {...props} viewBox="0 0 13 15" fill="none">
    <path
      d="M12.4146 6.62872C13.1107 7.0055 13.1139 8.00334 12.4202 8.38456L2.02699 14.0959C1.36178 14.4615 0.547816 13.9817 0.545392 13.2227L0.509123 1.86652C0.506699 1.10749 1.31758 0.622581 1.98511 0.983874L12.4146 6.62872Z"
      fill="#171717"
    />
  </svg>
)
