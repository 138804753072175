import React from 'react'

export const InterviewIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 56 56" fill="none" {...props}>
    <path
      d="M46.4206 28.1528V32.0757C46.4206 39.3447 42.2669 42.4599 36.0364 42.4599H15.268C9.03747 42.4599 4.88379 39.3447 4.88379 32.0757V19.6146C4.88379 12.3457 9.03747 9.23042 15.268 9.23042H21.4985C21.1985 10.1073 21.037 11.0765 21.037 12.1149V21.1146C21.037 23.353 21.7754 25.2452 23.0908 26.5605C24.4061 27.8759 26.2983 28.6143 28.5367 28.6143V31.8218C28.5367 32.9987 29.8751 33.7141 30.8674 33.068L37.5364 28.6143H43.5361C44.5746 28.6143 45.5437 28.4528 46.4206 28.1528Z"
      fill="#8080FF"
    />
    <path
      opacity="0.4"
      d="M51.0359 12.1149V21.1146C51.0359 24.5529 49.2822 27.1374 46.4207 28.1528C45.5438 28.4528 44.5747 28.6143 43.5362 28.6143H37.5365L30.8675 33.068C29.8752 33.7141 28.5368 32.9987 28.5368 31.8218V28.6143C26.2984 28.6143 24.4062 27.8759 23.0909 26.5605C21.7755 25.2452 21.0371 23.353 21.0371 21.1146V12.1149C21.0371 11.0765 21.1986 10.1073 21.4986 9.23041C22.514 6.36898 25.0985 4.6152 28.5368 4.6152H43.5362C48.0361 4.6152 51.0359 7.61509 51.0359 12.1149Z"
      fill="#8080FF"
    />
    <path
      opacity="0.4"
      d="M33.9597 49.0365H27.383V42.4599C27.383 41.5138 26.5984 40.7292 25.6523 40.7292C24.7062 40.7292 23.9216 41.5138 23.9216 42.4599V49.0365H17.345C16.3988 49.0365 15.6143 49.8211 15.6143 50.7672C15.6143 51.7134 16.3988 52.498 17.345 52.498H33.9597C34.9058 52.498 35.6904 51.7134 35.6904 50.7672C35.6904 49.8211 34.9058 49.0365 33.9597 49.0365Z"
      fill="#8080FF"
    />
    <path
      d="M35.8977 18.4608C34.9285 18.4608 34.167 17.6762 34.167 16.7301C34.167 15.784 34.9516 14.9994 35.8977 14.9994C36.8438 14.9994 37.6284 15.784 37.6284 16.7301C37.6284 17.6762 36.8438 18.4608 35.8977 18.4608Z"
      fill="#8080FF"
    />
    <path
      d="M42.3821 18.4608C41.4129 18.4608 40.6514 17.6762 40.6514 16.7301C40.6514 15.784 41.436 14.9994 42.3821 14.9994C43.3282 14.9994 44.1128 15.784 44.1128 16.7301C44.1128 17.6762 43.3282 18.4608 42.3821 18.4608Z"
      fill="#8080FF"
    />
    <path
      d="M29.3904 18.4608C28.4212 18.4608 27.6597 17.6762 27.6597 16.7301C27.6597 15.784 28.4443 14.9994 29.3904 14.9994C30.3365 14.9994 31.1211 15.784 31.1211 16.7301C31.1211 17.6762 30.3596 18.4608 29.3904 18.4608Z"
      fill="#8080FF"
    />
  </svg>
)

export const BackendIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" {...props}>
    <path
      d="M44.6245 13.8756L30.1558 6.0759C28.6328 5.24517 26.7867 5.24517 25.2637 6.0759L10.795 13.8756C9.73353 14.4525 9.0874 15.5601 9.0874 16.8293C9.0874 18.0754 9.73353 19.2062 10.795 19.7831L25.2637 27.5828C26.0252 27.9981 26.879 28.2058 27.7098 28.2058C28.5405 28.2058 29.3943 27.9981 30.1558 27.5828L44.6245 19.7831C45.686 19.2062 46.3321 18.0985 46.3321 16.8293C46.3321 15.5601 45.686 14.4525 44.6245 13.8756Z"
      fill="#8080FF"
    />
    <path
      opacity="0.4"
      d="M22.8869 30.2827L9.41053 23.5445C8.3721 23.0368 7.17215 23.0829 6.20296 23.6829C5.21069 24.306 4.63379 25.3444 4.63379 26.4982V39.2131C4.63379 41.4053 5.85682 43.3898 7.81828 44.3821L21.2716 51.1203C21.7331 51.3511 22.2408 51.4664 22.7485 51.4664C23.3484 51.4664 23.9484 51.3049 24.4792 50.9819C25.4714 50.3819 26.0483 49.3204 26.0483 48.1666V35.4517C26.0714 33.2364 24.8484 31.2519 22.8869 30.2827Z"
      fill="#8080FF"
    />
    <path
      opacity="0.4"
      d="M49.2165 23.6829C48.2242 23.0829 47.0242 23.0137 46.0089 23.5445L32.5556 30.2827C30.5941 31.2749 29.3711 33.2364 29.3711 35.4517V48.1666C29.3711 49.3204 29.948 50.3819 30.9403 50.9819C31.471 51.3049 32.071 51.4664 32.671 51.4664C33.1786 51.4664 33.6863 51.3511 34.1478 51.1203L47.6012 44.3821C49.5626 43.3898 50.7856 41.4284 50.7856 39.2131V26.4982C50.7856 25.3444 50.2087 24.306 49.2165 23.6829Z"
      fill="#8080FF"
    />
  </svg>
)

export const FrontendIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" {...props}>
    <path
      d="M40.4202 41.913H36.1511C35.205 41.913 34.4204 41.1284 34.4204 40.1823C34.4204 39.2362 35.205 38.4516 36.1511 38.4516H40.4202C41.3663 38.4516 42.1509 39.2362 42.1509 40.1823C42.1509 41.1284 41.3663 41.913 40.4202 41.913Z"
      fill="#8080FF"
    />
    <path
      d="M29.9666 41.913H15.0364C14.0902 41.913 13.3057 41.1284 13.3057 40.1823C13.3057 39.2362 14.0902 38.4516 15.0364 38.4516H29.9666C30.9127 38.4516 31.6973 39.2362 31.6973 40.1823C31.6973 41.1284 30.9357 41.913 29.9666 41.913Z"
      fill="#8080FF"
    />
    <path
      d="M40.42 33.2364H27.6589C26.7128 33.2364 25.9282 32.4518 25.9282 31.5057C25.9282 30.5596 26.7128 29.775 27.6589 29.775H40.42C41.3661 29.775 42.1507 30.5596 42.1507 31.5057C42.1507 32.4518 41.3661 33.2364 40.42 33.2364Z"
      fill="#8080FF"
    />
    <path
      d="M21.4284 33.2364H15.0364C14.0902 33.2364 13.3057 32.4518 13.3057 31.5057C13.3057 30.5596 14.0902 29.775 15.0364 29.775H21.4284C22.3745 29.775 23.1591 30.5596 23.1591 31.5057C23.1591 32.4518 22.3745 33.2364 21.4284 33.2364Z"
      fill="#8080FF"
    />
    <path
      opacity="0.4"
      d="M37.3972 5.38361H18.0595C9.65984 5.38361 4.65234 10.3911 4.65234 18.7908V38.1054C4.65234 46.5282 9.65984 51.5357 18.0595 51.5357H37.3741C45.7738 51.5357 50.7813 46.5282 50.7813 38.1285V18.7908C50.8044 10.3911 45.7969 5.38361 37.3972 5.38361Z"
      fill="#8080FF"
    />
  </svg>
)

export const DSAIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" {...props}>
    <path d="M36.9403 19.2292H18.4795V37.69H36.9403V19.2292Z" fill="#8080FF" />
    <path
      opacity="0.4"
      d="M11.5566 51.5357C15.3641 51.5357 18.4794 48.4204 18.4794 44.6129V37.6901H11.5566C7.74905 37.6901 4.63379 40.8054 4.63379 44.6129C4.63379 48.4204 7.74905 51.5357 11.5566 51.5357Z"
      fill="#8080FF"
    />
    <path
      opacity="0.4"
      d="M11.5566 19.2292H18.4794V12.3064C18.4794 8.49887 15.3641 5.38361 11.5566 5.38361C7.74905 5.38361 4.63379 8.49887 4.63379 12.3064C4.63379 16.114 7.74905 19.2292 11.5566 19.2292Z"
      fill="#8080FF"
    />
    <path
      opacity="0.4"
      d="M36.9399 19.2292H43.8627C47.6703 19.2292 50.7856 16.114 50.7856 12.3064C50.7856 8.49887 47.6703 5.38361 43.8627 5.38361C40.0552 5.38361 36.9399 8.49887 36.9399 12.3064V19.2292Z"
      fill="#8080FF"
    />
    <path
      opacity="0.4"
      d="M43.8627 51.5357C47.6703 51.5357 50.7856 48.4204 50.7856 44.6129C50.7856 40.8054 47.6703 37.6901 43.8627 37.6901H36.9399V44.6129C36.9399 48.4204 40.0552 51.5357 43.8627 51.5357Z"
      fill="#8080FF"
    />
  </svg>
)
