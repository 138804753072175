import { RadialGradient } from 'components'
import React from 'react'

import cornerBox from '../assets/images/corner-boxes.svg'
import { uniquePoints } from '../data/landing'

const UniqueSection = () => {
  return (
    <div className="flex flex-col items-center justify-center py-20 relative">
      <RadialGradient top="-40%" right="-300px" />
      <h2 className="text-5xl md:text-6xl text-center font-semibold mb-12">
        What makes us <span className="text-primary">Unique!</span>
      </h2>

      <div className="flex items-stretch flex-wrap w-full max-w-screen-xl">
        {uniquePoints.map((point) => (
          <div
            key={point.title}
            className="w-full md:w-1/3 p-5 relative overflow-hidden">
            <img
              loading="lazy"
              src={cornerBox.src}
              alt="box"
              className="absolute top-4 right-5 p-0.5 opacity-10 select-none"
            />
            <div className="h-full bg-gradient-to-br from-indigo-400 via-zinc-700 to-zinc-600 p-0.5 rounded-md">
              <div
                className="min-h-full shadow-sm p-6 rounded-md"
                style={{
                  background:
                    'linear-gradient(114.7deg, #27272A -9.77%, #18181B 110.32%)'
                }}>
                <point.icon className="w-16 h-16 mr-auto my-2 bg-zinc-700 p-3 rounded-lg" />

                <h3 className="text-white text-left font-medium text-lg lg:text-2xl my-4">
                  {point.title}
                </h3>

                <p className="text-left text-zinc-400">{point.desc}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default UniqueSection
