import { JoinDiscordSection, RadialGradient } from 'components'
import FounderSection from 'components/Sections/FounderSection'
import { AppPage } from 'interfaces'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { useLoginState } from 'state'

import CurriculumSection from '../components/CurriculumSection'
import DevsnestBootCampHeroSection from '../components/HeroSection/DevsnestBootCampHeroSection'
import NewCourseSection from '../components/NewCourseSection'
import PlacedSection from '../components/PlacedSection'
import SqlMiniCourseMarketing from '../components/SqlMiniCourseMarketing'
import StudentsFeedBackSection from '../components/StudentsFeedBackSection'
import SuccessStorySection from '../components/SuccessStorySection'
import UniqueSection from '../components/UniqueSection'

const LandingPage: AppPage = () => {
  const router = useRouter()
  const { ref } = router.query
  const referral_code = typeof ref === 'string' ? ref : ''

  const { user } = useLoginState()

  const [referredCompany, setReferralCode] = useState(referral_code || '')

  useEffect(() => {
    if (referral_code) {
      setReferralCode(referral_code)
    }
  }, [referral_code])

  return (
    <>
      <DevsnestBootCampHeroSection
        to={
          user
            ? '/dashboard'
            : referredCompany
            ? `/login?ref=${referredCompany}`
            : '/login'
        }
      />
      <SqlMiniCourseMarketing
        to={
          user
            ? '/mini-course?course_module_id=7'
            : '/login?redirect=/mini-course?course_module_id=7'
        }
      />
      <NewCourseSection />
      <PlacedSection />
      <SuccessStorySection />
      <CurriculumSection />

      <UniqueSection />

      {/* <PricingSection /> */}
      <JoinDiscordSection />

      <StudentsFeedBackSection />

      <div className="py-24 flex flex-col relative">
        <RadialGradient top="-20%" right="-300px" />
        <FounderSection />
      </div>
    </>
  )
}

LandingPage.layout = {
  pageClassName: 'max-w-full overflow-hidden mx-0',
  navProps: {
    className: 'bg-opacity-50 md:bg-opacity-10'
  }
  // showMarketingInfoNotification: true
}

LandingPage.seo = {
  title: 'Devsnest - Coding Bootcamp',
  canonical: 'https://devsnest.in/',
  openGraph: {
    url: 'https://devsnest.in/'
  }
}

export default LandingPage
