import { Button } from 'components'
import { useRouter } from 'next/router'
import React from 'react'

import { RocketIcon } from '@radix-ui/react-icons'

const SqlMiniCourseMarketing = ({ to }: { to: string }) => {
  const router = useRouter()
  return (
    <div className="flex flex-col justify-center items-center px-6 mb-20 pb-16">
      <h1 className="text-3xl xl:text-5xl font-bold mb-8 pb-4 text-primary">
        SQL MiniBootcamp
      </h1>
      <div className="flex flex-col xl:flex-row mx-auto justify-between w-full">
        <div className="w-full xl:w-[70%] flex flex-col gap-8 md:pl-8">
          <h1 className="text-3xl xl:text-5xl font-bold">
            Kickstart Your SQL Skills!
          </h1>
          <div className="flex flex-col justify-between gap-3 p-2 pt-0">
            <p className="text-xl md:text-xl w-[80%]">
              Master the essentials of SQL through real-time coding right on our
              website. Cover all the important topics, from basic queries to
              advanced operations, in a hands-on, engaging format. Launch your
              journey towards SQL proficiency today.
            </p>
            <p className="ml-4 text-lg md:text-xl mt-3">
              - You will get a certificate after completion of the course
            </p>
            <p className="ml-4 text-lg md:text-xl">
              – no experience required, completely free.
            </p>
            <p className="ml-4 text-lg md:text-xl">
              - You will get access to the community
            </p>
          </div>
          <div className="mb-4 flex flex-col md:flex-row gap-4 items-center font-medium">
            <span className="px-4 py-1 bg-primary-dark rounded-xl">
              Free Course
            </span>
            <span className="px-4 py-1 bg-primary-dark rounded-xl">
              10 Days
            </span>
            <span className="px-4 py-1 bg-primary-dark rounded-xl">
              Beginner Friendly
            </span>
          </div>
        </div>
        <div className="w-full xl:w-[30%] flex flex-col  justify-center md:pr-8">
          <h2 className="text-3xl xl:text-5xl font-semibold">You will learn</h2>
          <ul className="my-4 flex flex-col gap-3 pl-6">
            <li className="text-xl flex items-center gap-2">
              <RocketIcon className="text-green-500 w-5 h-5" />
              Introduction to SQL
            </li>
            <li className="text-xl flex items-center gap-2">
              <RocketIcon className="text-green-500 w-5 h-5" /> Data Retrieval
            </li>
            <li className="text-xl flex items-center gap-2">
              <RocketIcon className="text-green-500 w-5 h-5" /> Data
              Modification
            </li>
            <li className="text-xl flex items-center gap-2">
              <RocketIcon className="text-green-500 w-5 h-5" /> Joins
            </li>
            <li className="text-xl flex items-center gap-2">
              <RocketIcon className="text-green-500 w-5 h-5" /> Aggregate
              Functions
            </li>
            <li className="text-xl flex items-center gap-2">
              <RocketIcon className="text-green-500 w-5 h-5" /> Sub-queries
            </li>
            <li className="text-xl flex items-center gap-2">
              <RocketIcon className="text-green-500 w-5 h-5" /> Set Operations
            </li>
          </ul>

          <Button
            variant={'primary'}
            className="mt-8 w-full flex justify-center"
            onClick={() => {
              // console.log(router, to)
              router.push(to)
            }}>
            Start Learning
          </Button>
        </div>
      </div>
    </div>
  )
}

export default SqlMiniCourseMarketing
