import React from 'react'

export const MessageIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="41"
    height="34"
    viewBox="0 0 41 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M36.166 20.8917V24.2209C36.166 30.3897 32.641 33.0334 27.3535 33.0334H9.72851C4.44102 33.0334 0.916016 30.3897 0.916016 24.2209V13.6459C0.916016 7.47712 4.44102 4.83337 9.72851 4.83337H15.016C14.7614 5.57754 14.6243 6.40004 14.6243 7.28129V14.9188C14.6243 16.8184 15.251 18.4242 16.3673 19.5405C17.4835 20.6567 19.0893 21.2834 20.9889 21.2834V24.0054C20.9889 25.0042 22.1248 25.6113 22.9668 25.063L28.6264 21.2834H33.7181C34.5993 21.2834 35.4218 21.1463 36.166 20.8917Z"
      fill="#8080FF"
    />
    <path
      opacity="0.4"
      d="M40.0833 7.28121V14.9188C40.0833 17.8367 38.595 20.03 36.1667 20.8916C35.4225 21.1462 34.6 21.2833 33.7187 21.2833H28.6271L22.9675 25.0629C22.1254 25.6112 20.9896 25.0041 20.9896 24.0053V21.2833C19.09 21.2833 17.4842 20.6566 16.3679 19.5404C15.2517 18.4241 14.625 16.8183 14.625 14.9188V7.28121C14.625 6.39996 14.7621 5.57746 15.0167 4.83329C15.8783 2.40496 18.0717 0.916626 20.9896 0.916626H33.7187C37.5375 0.916626 40.0833 3.46246 40.0833 7.28121Z"
      fill="#8080FF"
    />
  </svg>
)

export const BookIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="41"
    height="38"
    viewBox="0 0 41 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M2 2H12.6174C14.4947 2 16.295 2.79094 17.6225 4.19882C18.9499 5.6067 19.6957 7.5162 19.6957 9.50725V35.7826C19.6957 34.2893 19.1363 32.8572 18.1408 31.8013C17.1452 30.7454 15.7949 30.1522 14.387 30.1522H2V2Z"
      stroke="#8080FF"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38.9997 2H27.4171C25.3691 2 23.4051 2.79094 21.957 4.19882C20.5089 5.6067 19.6953 7.5162 19.6953 9.50725V35.7826C19.6953 34.2893 20.3055 32.8572 21.3915 31.8013C22.4776 30.7454 23.9507 30.1522 25.4866 30.1522H38.9997V2Z"
      stroke="#8080FF"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const PeopleIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="43"
    height="36"
    viewBox="0 0 43 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M30.4444 34V30.4445C30.4444 28.5585 29.6952 26.7498 28.3616 25.4162C27.028 24.0826 25.2193 23.3334 23.3333 23.3334H9.11111C7.22513 23.3334 5.41639 24.0826 4.0828 25.4162C2.7492 26.7498 2 28.5585 2 30.4445V34"
      stroke="#8080FF"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.2244 16.2222C20.1517 16.2222 23.3355 13.0385 23.3355 9.11111C23.3355 5.18375 20.1517 2 16.2244 2C12.297 2 9.11328 5.18375 9.11328 9.11111C9.11328 13.0385 12.297 16.2222 16.2244 16.2222Z"
      stroke="#8080FF"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.1107 34V30.4445C41.1095 28.8689 40.5851 27.3383 39.6198 26.093C38.6545 24.8478 37.3029 23.9584 35.7773 23.5645"
      stroke="#8080FF"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.666 2.23108C30.1956 2.62273 31.5514 3.51232 32.5196 4.75963C33.4878 6.00694 34.0133 7.541 34.0133 9.11997C34.0133 10.6989 33.4878 12.233 32.5196 13.4803C31.5514 14.7276 30.1956 15.6172 28.666 16.0089"
      stroke="#8080FF"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
