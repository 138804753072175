import { GetServerSideProps } from 'next'

export { LandingPage as default } from 'features/Marketing/LandingV0'

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { authorization } = context.req.cookies
  if (authorization) {
    return {
      redirect: {
        destination: '/dashboard',
        permanent: false
      },
      props: {}
    }
  }
  return { props: {} }
}
