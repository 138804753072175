import { RadialGradient } from 'components'
import React from 'react'

import companies_logo from '../assets/images/company_logo.svg'

const PlacedSection = () => {
  return (
    <div className="my-16 flex flex-col w-full max-w-5xl mx-auto py-24 md:py-8 px-8 relative">
      <RadialGradient top="-40%" right="-300px" />
      <h2 className="text-5xl md:text-6xl font-semibold text-center mb-6 text-primary">
        Our Alumni work at
      </h2>

      <img
        src={companies_logo.src}
        alt="companies"
        style={{ aspectRatio: 'auto' }}
        className="mb-4 w-[953] h-[243]"
      />
    </div>
  )
}

export default PlacedSection
