import kshitizImage from '../assets/images/people/kshitiz.webp'
import vedanshImage from '../assets/images/people/vedansh.webp'

export const founders = [
  {
    image: vedanshImage.src,
    name: 'Vedansh Garg',
    title: 'Founder, Devsnest',
    subtitle: 'Ex-Hackerrank',
    plot: 'Vedansh has worked as a senior developer at Hackerrank, where he contributed in scaling the developer community to 10M. In addition, he has worked with 100+ underprivileged students and helped them get successfully placed in software engineering jobs.',
    twitter: 'https://twitter.com/vedansh_garg',
    linkedin: 'https://www.linkedin.com/in/vedanshgarg'
  },
  {
    image: kshitizImage.src,
    name: 'Kshitiz Miglani',
    title: 'Founder, Devsnest',
    subtitle: 'Ex-Amazon',
    plot: "Kshitiz has worked as a developer in Amazon, PayTM, and Samsung, leading critical projects and creating scalable solutions. He has also worked in multiple early start ups related to tech and education domain. Has been mentoring masses to become great engineers and leaders for India's growing Silicon Valley.",
    twitter: 'https://twitter.com/MiglaniKshitiz',
    linkedin: 'https://www.linkedin.com/in/kshitizmiglani'
  }
]
