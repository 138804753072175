import type { LottieProps } from 'react-lottie'

import { heroSectionAnimationData } from './index'

export const heroSectionAnimation: LottieProps = {
  isClickToPauseDisabled: true,
  options: {
    loop: true,
    autoplay: true,
    animationData: heroSectionAnimationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }
}
