import { AnimatedModal, RadialGradient } from 'components'
import { LayoutGroup } from 'framer-motion'
import dynamic from 'next/dynamic'
import React, { useState } from 'react'

import gridGrayDots from '../assets/images/GridGrayDots.svg'
import { students_empowered } from '../data/landing'

import EmpoweredStudentCard from './EmpoweredStudentCard'

const ReactPlayer = dynamic(() => import('react-player/youtube'))

const SuccessStorySection = () => {
  const [modalState, setModalState] = useState<{
    isOpen: boolean
    link: string
  }>({ isOpen: false, link: '' })

  return (
    <div className="min-h-screen flex items-center justify-center px-8 relative mb-16">
      <RadialGradient top="10%" left="-300px" />

      <div className="w-full flex flex-col items-center">
        <h2 className="text-5xl max-w-[16ch] mx-auto h-1/2 my-auto md:text-6xl font-semibold text-center">
          On a mission to <span className="text-primary">empower students</span>
        </h2>

        <a
          href="https://www.youtube.com/playlist?list=PLqcJACtjWm_WMWp4KGjWdMHLWR75CRDRY"
          target="_blank"
          rel="noreferrer"
          className="text-white hover:text-zinc-300 transition-colors text-center font-medium mx-auto text-2xl mt-8 mb-12 py-2">
          Success Stories of Our Students →
        </a>

        <div className="flex flex-wrap relative justify-evenly">
          <LayoutGroup>
            {students_empowered.map((student) => (
              <EmpoweredStudentCard
                key={student.name}
                {...student}
                onClick={(link) => setModalState({ isOpen: true, link })}
              />
            ))}
            <AnimatedModal
              isOpen={modalState.isOpen}
              className="block w-full max-w-2xl h-96"
              onClose={() => {
                setModalState({ isOpen: false, link: '' })
              }}>
              <ReactPlayer
                url={modalState.link}
                height="100%"
                width="100%"
                controls
              />
            </AnimatedModal>
          </LayoutGroup>
          <img
            loading="lazy"
            src={gridGrayDots.src}
            alt="illustration"
            className="h-72 w-72 hidden md:block select-none absolute -left-1/4 -bottom-1/4"
          />
          <img
            loading="lazy"
            src={gridGrayDots.src}
            alt="illustration"
            className="h-52 w-52 hidden md:block select-none absolute -top-36 -right-24"
          />
        </div>

        <a
          href="https://youtube.com/Devsnest?sub_confirmation=1"
          target="_blank"
          rel="noreferrer"
          className="text-primary hover:text-primary-light transition-colors text-center font-medium text-2xl mx-auto py-2 mt-8">
          Checkout Our Youtube Channel →
        </a>
      </div>
    </div>
  )
}

export default SuccessStorySection
