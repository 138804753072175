import {
  BackendIcon,
  BookIcon,
  DSAIcon,
  FrontendIcon,
  GuidanceIcon,
  InterviewIcon,
  InterviewPrepIcon,
  LiveIcon,
  MessageIcon,
  PeerLearningIcon,
  PeopleIcon,
  ProjectIcon,
  TechTalksIcon
} from 'assets/icons'

import airtelXLabsImg from '../assets/images/companies/airtel_x_labs.webp'
import deShawCoImg from '../assets/images/companies/de_shaw_co.webp'
import infoEdgeImg from '../assets/images/companies/infoedge.webp'
import playmentImg from '../assets/images/companies/playment.webp'
import kshitizImage from '../assets/images/people/kshitiz.webp'
import shariqueImg from '../assets/images/people/mohammed_sharique.webp'
import ridhimaKapoorImage from '../assets/images/people/ridhima-kapoor.webp'
import sagarImg from '../assets/images/people/sagar_gulani.webp'
import shrishtiImg from '../assets/images/people/shrishti_agarwal.webp'
import utkarshImg from '../assets/images/people/utkarsh_nagdev.webp'
import vedanshImage from '../assets/images/people/vedansh.webp'
import vedantJainImage from '../assets/images/people/vedant-jain.webp'

// export const CUSTOM_REGISTER_RAZOR_PAY_LINK =
//   'https://pages.razorpay.com/pl_LZVMtQBNeyGxbh/view'

const FullStackNotionLink =
  'https://water-pin-778.notion.site/THE-Devsnest-Bootcamp-A-to-Z-82dd13410b374754b2ff934c70561416'

export const CUSTOM_REGISTER_RAZOR_PAY_LINK = ''

export const KNOW_MORE_NOTION_LINK = FullStackNotionLink

export const founders = [
  {
    image: vedanshImage.src,
    name: 'Vedansh Garg',
    title: 'Founder, Devsnest',
    subtitle: 'Ex-Hackerrank',
    plot: 'Vedansh has worked as a senior developer at Hackerrank, where he contributed in scaling the developer community to 10M. In addition, he has worked with 100+ underprivileged students and helped them get successfully placed in software engineering jobs.',
    twitter: 'https://twitter.com/vedansh_garg',
    linkedin: 'https://www.linkedin.com/in/vedanshgarg'
  },
  {
    image: kshitizImage.src,
    name: 'Kshitiz Miglani',
    title: 'Founder, Devsnest',
    subtitle: 'Ex-Amazon',
    plot: "Kshitiz has worked as a developer in Amazon, PayTM, and Samsung, leading critical projects and creating scalable solutions. He has also worked in multiple early start ups related to tech and education domain. Has been mentoring masses to become great engineers and leaders for India's growing Silicon Valley.",
    twitter: 'https://twitter.com/MiglaniKshitiz',
    linkedin: 'https://www.linkedin.com/in/kshitizmiglani'
  }
]

export const curriculum = [
  {
    icon: DSAIcon,
    title: 'Basic Programming and DSA',
    desc: 'Covering everything in all its depth so that you don’t ever feel DSA is tough again',
    tag: '',
    show: true
  },
  {
    icon: FrontendIcon,
    title: 'WebDev - Frontend',
    desc: 'Designing Dope UI UX components which make users love the Apps!',
    tag: '',
    show: true
  },
  {
    icon: BackendIcon,
    title: 'WebDev - Backend',
    desc: 'Understanding how the tech giants like Amazon, Uber, Google process data for trillions or requests every minute.',
    tag: '',
    show: true
  },
  {
    icon: InterviewIcon,
    title: 'Interview Prep',
    desc: 'From resume preparation to mock interviews everything we make sure you perform confidently and your projects put you above the crowd.',
    tag: '',
    show: true
  }
]

export const uniquePoints = [
  {
    icon: LiveIcon,
    title: 'Recorded Classes',
    desc: 'we do bring a fun class-like experience virtually.'
  },
  {
    icon: GuidanceIcon,
    title: 'Personalized Guidance',
    desc: 'Everyone is different and so are your talents and problems. We nurture you through personalized attention into the best you.'
  },
  {
    icon: ProjectIcon,
    title: 'Project Driven Curriculum',
    desc: 'Reading the whole Curriculum is a nightmare, only Sheldon can handle so much of theory. Our 10+ hands-on projects, makes it exciting.'
  },
  {
    icon: TechTalksIcon,
    title: 'Guest Tech Talk',
    desc: 'Our “Cool Tech Talks” by industry experts on trending technologies keep you upgraded to the latest version.'
  },
  {
    icon: PeerLearningIcon,
    title: 'Peer Learning',
    desc: 'Discussions and debates are the best way to learn, We encourage group collaboration and pair programming.'
  },
  {
    icon: InterviewPrepIcon,
    title: 'Interview Preparation',
    desc: 'We end your stage fear of interviews, by having mock "live like" interviews by mentors from FAANG type companies.'
  }
]

export const students_messages = [
  {
    name: 'Ridhima Kapoor',
    image: ridhimaKapoorImage.src,
    company: 'Expedia',
    message:
      "I am grateful to Devsnest and Vedansh Garg sir, for helping me understand my potential. Their personalized peer learning approach worked best for me. The group projects with peers made learning easy and effortless. Thank you, Vedansh Garg sir and Sai Ahladini Tripathy ma'am, for your guidance, motivation, and for always being there. Doesn't matter it was day or night, they were there to help. Thank you Devsnest!"
  },
  {
    name: 'Vedant Jain',
    image: vedantJainImage.src,
    company: 'Myntra',
    message:
      'Thanking Vedansh Garg Sir for his immense guidance and support. Probably joining Devsnest was one of the best decisions I have made so far. Devsnest made me the Engineer I always wanted to be. I am really looking forward to a wonderful journey ahead.'
  }
]

export const students_empowered = [
  {
    name: 'Shrishti Agarwal',
    link: 'https://www.youtube.com/watch?v=v9_2rqrD-Lk&ab_channel=Devsnest',
    img: shrishtiImg.src,
    companyImg: playmentImg.src
  },
  {
    name: 'Utkarsh Nagdev',
    link: 'https://www.youtube.com/watch?v=ZAZIK7pMImA&ab_channel=Devsnest',
    img: utkarshImg.src,
    companyImg: airtelXLabsImg.src
  },
  {
    name: 'Mohammed Sharique',
    link: 'https://www.youtube.com/watch?v=gz1jrTDQcHY&ab_channel=Devsnest',
    img: shariqueImg.src,
    companyImg: deShawCoImg.src
  },
  {
    name: 'Sagar Guglani',
    link: 'https://www.youtube.com/watch?v=gCX6wgGCCXk&ab_channel=Devsnest',
    img: sagarImg.src,
    companyImg: infoEdgeImg.src
  }
]

export const newcourse_details = [
  {
    id: 1,
    icon: MessageIcon,
    desc: 'Recorded Classes and Doubt Sessions'
  },
  {
    id: 2,
    icon: BookIcon,
    desc: 'Most Active learning environment, Learn with friends and be part of the most supportive  community'
  },
  {
    id: 3,
    icon: PeopleIcon,
    desc: 'Mentorship from Industry experts of top tech companies like Google, Facebook, Amazon and  HackerRank'
  }
]
