import React from 'react'

import { tx } from '@twind/core'

type StatusTagProps = {
  wrapperClassName?: string
  textClassName?: string
  tag: string
  circleClassName?: string
}

const StatusTag = (props: StatusTagProps) => {
  const { wrapperClassName, textClassName, tag, circleClassName } = props

  if (!tag) return null

  return (
    <div
      className={tx(
        'rounded-full bg-[#E1FCEF] px-4 py-1 flex items-center gap-2',
        tag === 'Recorded' && 'bg-red-500',
        wrapperClassName
      )}>
      <div
        className={tx(
          'w-1.5 h-1.5 rounded-full bg-green-500',
          tag === 'Recorded' && 'bg-white',
          circleClassName
        )}
      />
      <p
        className={tx(
          'text-sm font-semibold text-green-500',
          tag === 'Recorded' && 'text-white',
          textClassName
        )}>
        {tag}
      </p>
    </div>
  )
}

export default StatusTag
