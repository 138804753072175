import React from 'react'

import { newcourse_details } from '../data/landing'

const NewCourseSection = () => {
  return (
    <div className="flex flex-col items-center px-8 mx-auto justify-center">
      <div className="flex flex-col items-start w-full">
        {/* <div className="mb-4"> */}
        {/*  <StatusTag tag="New Course" textClassName="text-uppercase" /> */}
        {/* </div> */}
        {/* <h2 className="text-5xl md:text-6xl font-bold text-center w-full">
          Join our 2 Months <br />
          <span className="text-primary"> DSA Bootcamp</span>
        </h2> */}

        <h2 className="text-5xl md:text-6xl font-bold text-center w-full">
          Join our zero cost 7 Months <br />
          <span className="text-primary"> Fullstack Bootcamp</span>
        </h2>
      </div>
      <div className="w-full max-w-6xl mx-auto flex flex-col justify-center items-center">
        {/* <p className="text-xl md:text-2xl font-medium text-center py-6 md:px-16 text-content my-2">
          View the Full Curriculum{' '}
          <a
            href={KNOW_MORE_NOTION_LINK}
            className="text-primary hover:text-primary-dark underline">
            here
          </a>
        </p> */}

        <p className="text-xl md:text-2xl font-medium text-center py-6 md:px-16 text-content my-2">
          Kickstart your technical career with peer and project driven 7 months
          Full Stack bootcamp and get placement opportunities in top tech
          companies.
        </p>

        <div className="flex md:flex-row flex-col">
          {newcourse_details.map((point) => (
            <div
              className="md:w-1/3 md:px-5 py-5 overflow-hidden min-h-full w-full"
              key={point.id}>
              <div className="bg-gradient-to-br from-indigo-400 via-zinc-700 to-zinc-600 p-0.5 rounded h-full">
                <div
                  className="shadow-sm rounded flex flex-col items-center py-7 px-4 h-full"
                  style={{
                    background:
                      'linear-gradient(114.7deg, #27272A -9.77%, #18181B 110.32%)'
                  }}>
                  <point.icon />
                  <p className="text-center mt-6 text-base font-medium text-zinc-100">
                    {point.desc}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default NewCourseSection
