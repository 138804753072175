import { Link } from 'components'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { useLoginState } from 'state'

import { CUSTOM_REGISTER_RAZOR_PAY_LINK } from '../data/landing'

const RegisterButton = () => {
  const router = useRouter()
  const { ref } = router.query
  const referral_code = typeof ref === 'string' ? ref : ''
  const { user } = useLoginState()

  const [referredCompany, setReferralCode] = useState(referral_code || '')

  useEffect(() => {
    if (referral_code) {
      setReferralCode(referral_code)
    }
  }, [referral_code])

  const href =
    CUSTOM_REGISTER_RAZOR_PAY_LINK ||
    (user
      ? '/dashboard'
      : referredCompany
      ? `/login?ref=${referredCompany}`
      : '/login')

  return (
    <Link
      href={href}
      className="inline-block bg-primary-DEFAULT hover:bg-primary-dark transition-colors mr-4 md:mr-8 px-4 md:px-7 py-2 md:py-4 rounded-md text-base md:text-xl font-semibold">
      Register Now
    </Link>
  )
}

export default RegisterButton
